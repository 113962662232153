
<script>
    import footerLayout from "../../layouts/footerLayout";
    import headerLayout from "../../layouts/headerLayout";
    import commonApi from "../apiModel/apiModel";

    import Vue from 'vue'

    import Drawer from "vue-simple-drawer"

    import { VuePicker, VuePickerOption } from '@invisiburu/vue-picker'
import '@invisiburu/vue-picker/dist/vue-picker.min.css'
Vue.component('VuePicker', VuePicker)
Vue.component('VuePickerOption', VuePickerOption)

import VueSimpleRangeSlider from 'vue-simple-range-slider';
import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css'

    // carousel
    import carousel from 'vue-owl-carousel';

    // Add class when scrolled
import ClassOnScroll from 'vue-class-on-scroll'

    // For Scroll Spy (lunch,Startes)
 import VueScrollactive from 'vue-scrollactive';

 Vue.use(VueScrollactive);

 import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
Vue.use(VueCustomTooltip)

// import VueShimmer from '@gomarky/vue-shimmer' // shimmer div
// Vue.use(VueShimmer)
    
    export default {
        data () {
            return{
                rangeFilter:[0,10000],
                allFilters:[],
                isFilterApplied:0,
                filterTypeArr:[],
                openDrawer: false,
                selectFoodType:'',
                bannerID: '',
                collectionKeyword:'',
                collectionType:'',
                restaurantList:undefined,
                restCount:'',
                shimmer:0,
                bannerDetails: '',
                bannerShimmer: 0,
                allFoodTypes:[],
                addressInput:undefined,
			    latitude:undefined,
			    longitude:undefined,
                navIcons :['<i class="bx bx-chevron-left"></i>', '<i class="bx bx-chevron-right"></i>'],
                navIconsCaterer :['<i class="bx bx-left-arrow-alt"></i>', '<i class="bx bx-right-arrow-alt"></i>'],
            }
        },
        directives: {
						'class-on-scroll': ClassOnScroll
						},
						
    components: { 
                    headerLayout,
                    footerLayout,
                    carousel,
                    Drawer,
                    VueSimpleRangeSlider
                },
    mounted()
    {

        if (JSON.parse(localStorage.getItem('placesAddress')) !== null) {
			this.addressInput = JSON.parse(localStorage.getItem('placesAddress')).address;
			this.latitude = JSON.parse(localStorage.getItem('placesAddress')).latitude;
			this.longitude = JSON.parse(localStorage.getItem('placesAddress')).longitude;
		}
        // console.log(this.latitude);
        // console.log(this.longitude);
        if(this.latitude == "" && this.longitude == "")
        {
            alert("please select delivery location")
            this.$router.push('/');
        }
        if (this.$userData !== null) {
			this.userID = this.$userData.userID;
			this.corporateBranchID = this.$userData.corporateBranchID;
		}

        if(this.$route.params.id && this.$route.params.type)
            {
                var collectionID = this.$route.params.id;
                var collectionType = this.$route.params.type;
                // alert(collectionID);
                // switch(this.$route.params.type)
                // {
                //     case 'Banner':
                //         this.bannerID = collectionID;
			    //         this.getRestaurantsByBannerDetails();
                //     break;
                //     case 'Speciality':
                //         this.collectionKeyword = collectionID;
                //         this.collectionType = collectionType;
			    //         this.getRestaurantsByCollectionTypeAndID(); // ID =  Keyword 
                //     break;
                // }
                if(this.$route.params.type == 'Banner')
                {
                    this.bannerID = collectionID;
			        this.getRestaurantsByBannerDetails(); // BYBannerID
                } // if type is banner get banner restaurants

                if(this.$route.params.type == 'Dish' || this.$route.params.type == 'Speciality')
                {
                    this.collectionKeyword = collectionID;
                    this.collectionType = collectionType;
                    this.getRestaurantsByCollectionTypeAndKey(); // ID =  Keyword 
                }
            }
			this.getAllFilters();

    },

    methods:
    {
        getAllFilters()
        {
            this.getAllFiltersCommonApi = commonApi.getAllFiltersApi;
            this.getAllFiltersCommonApi();
        },

        applyFilter()
        {
            // console.log(this.specialityfilter);
            this.appliedFilterArr = [];
            for (let i = 0; i < this.filterTypeArr.length; i++) {
                // var element = this.filterTypeArr[i];
                // console.log(element);
                var filterTypeName = this.allFilters[i].identifier
                var filterArr = {
                    "filterType":filterTypeName,
                    "dataArr" : this.filterTypeArr[i]                      
                };
                this.appliedFilterArr.push(filterArr);
            }
            var filterArr = {
                "filterType":"rangeSilder",
                "dataArr" : this.rangeFilter                      
            };
                this.appliedFilterArr.push(filterArr);
            this.isFilterApplied = 1;
            this.getRestaurantsByBannerDetails();
             this.toggleDrawer();
        },
        clearFilter()
        {
            this.isFilterApplied = 0;
            this.rangeFilter = [0,10000];
            this.filterTypeArr = [];
             this.toggleDrawer();
            this.getRestaurantsByBannerDetails();
        },

        toggleDrawer() {
      this.openDrawer = !this.openDrawer
    },
    changeFoodType()
		{
            if(this.bannerID != '')
            {
			    this.getRestaurantsByBannerDetails();
            }
            if(this.collectionKeyword != '' && this.collectionType != '')
            {
			    this.getRestaurantsByCollectionTypeAndKey(); // ID =  Keyword 
            }
		},
        getRestaurantsByBannerDetails()
        {
            this.shimmer = 0;
            var appliedFilterArr = [];
			if(this.isFilterApplied == 1)
			{
				appliedFilterArr = this.appliedFilterArr;
			}
            // console.log(appliedFilterArr);
            // console.log(this.isFilterApplied);
            // return;
            let getRestFromBannerFormData = JSON.stringify({ bannerID: this.bannerID,
                                                             latitude: this.latitude,
                                                             longitude: this.longitude,
                                                             appliedFilterArr:appliedFilterArr,
														     foodTypeID:this.selectFoodType
                                                            });

                this.axios.post('/getRestaurantsByBannerDetails', getRestFromBannerFormData)
                .then((res)=>{
                    let status = res.status;
                    if(status == 200 && res.data.status==200)
                    {
                        this.shimmer = 1;
					    this.restaurantList = res.data.data;
                        this.bannerDetails = res.data.bannerDetails;
                        this.allFoodTypes = res.data.allFoodTypes;
                        // console.log(res.data.bannerDetails)                        
                    }
                    else{
                        // console.log(res.data.message);
                        alert(res.data.message);
                        // this.$router.push('/');
                    }
                }).catch((error) => {
                            this.searching = 0;
                            console.log(error.response.data.message);
                        });
        },

        getRestaurantsByCollectionTypeAndKey()
        {
            this.shimmer = 0;
            let getRestFromTypeAndKeyFormData = JSON.stringify({ collectionKeyword: this.collectionKeyword,
                                                             collectionType: this.collectionType,
                                                             latitude: this.latitude,
                                                             longitude: this.longitude,
														     foodTypeID:this.selectFoodType
                                                            });

                this.axios.post('/getRestaurantsByCollectionTypeAndID', getRestFromTypeAndKeyFormData)
                .then((res)=>{
                    let status = res.status;
                    if(status == 200 && res.data.status==200)
                    {
                        this.shimmer = 1;
					    this.restaurantList = res.data.data;
                        this.bannerDetails = res.data.bannerDetails;
                        this.allFoodTypes = res.data.allFoodTypes;
                        // console.log(res.data.bannerDetails)                        
                    }
                    else{
                        // console.log(res.data.message);
                        alert(res.data.message);
                        // this.$router.push('/');
                    }
                }).catch((error) => {
                            this.searching = 0;
                            console.log(error.response.data.message);
                        });
        },
    }
}
</script>

<template>
    <div class="container-fuild m-0 p-0">
        <headerLayout />
	<!-- header layout -->

        <main>
            <div class="page_header element_to_stick">
                <div class="container">
                    <div v-if="bannerDetails != ''">
                        <h2 class="title_head bannerTitle">{{bannerDetails.title}}</h2>
                        <h4 class="bannerDesc">{{bannerDetails.description}}</h4>
                    </div>	       
                </div>
            </div>
            <!-- /page_header -->

            <nav class="secondary_nav sticky_horizontal"  v-class-on-scroll="{ class: 'is_stuck', offset: 0 }">
		    <div class="container">
		        <div class="row">
                        <div class="col-6 col-xl-2 col-lg-2 col-md-2  d-md-block searchCafes">
                          <vue-picker placeholder="Select Diet" v-if="allFoodTypes.length !== 0" v-model="selectFoodType" @input="changeFoodType" style="max-width:170px!important;margin-left:auto;">
											<vue-picker-option value="0"><div class="foodType align-center" style="background:#a4827d;">
												A
											</div> All</vue-picker-option>
											<vue-picker-option v-for="foodTypes in allFoodTypes" v-bind:key="foodTypes.typeID" :value="foodTypes.typeID">
												<div class="foodType align-center" :style="{background : foodTypes.color}">
													{{foodTypes.shortCode}}
												</div>
												{{foodTypes.foodTypeName}}
											</vue-picker-option>
							</vue-picker>
                        </div>
                        <div class="col-xl-6 col-lg-5 col-md-5 d-none d-md-block"></div>
                        <div class="col-6 col-xl-4 col-lg-5 col-md-5">
                            <div class="row">
                                <div class="col-xl-8 col-lg-8 col-md-8 searchCafes">
                                    <!-- <div class="row">
                                       <label class="col-md-2 col-form-label">Sort: </label>
                                        <div class="col-md-10">
                                        <select class="form-select">
                                            <option>Relevance</option>
                                            <option>Large select</option>
                                            <option>Small select</option>
                                        </select>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 searchCafes">
                                    <div class="row">
                                       <label class="col-4 col-form-label">Filter: </label>
                            <div class="col-8">
                                <div class="filter">
                                    <img @click="toggleDrawer" src="../../templateAssests/img/ckgallery/filter-icon.png" width="20" height="20">
                                <!-- <i class="bx bx-filter-alt filter"></i> -->
                                </div>
                            </div>
                        </div>
                        <Drawer @close="toggleDrawer" align="right" :maskClosable="true" :closeable="true">
                                             <div v-if="openDrawer">
                                                 <div class="filterHead">
                                                     <h4>Filters</h4>
                                                 </div>
                                                 <div class="filterSection" v-for="(filter,index) in allFilters" v-bind:key="filter.filterCatName">
                                                    <div class="specialitySection" v-if="filter.filterCatName != ''">
                                                        <h5>
                                                            {{filter.filterCatName}}
                                                        </h5>
                                                    </div>
                                                        <div class="row" v-if="filter.displayType == 'checkbox'">
                                                            <div class="col-6 specialityCol"  v-for="filterData in filter.dataArr" v-bind:key="filterData.filterID">
                                                                <b-form-group v-slot="{ ariaDescribedby }">
                                                                    <b-form-checkbox-group
                                                                        id="checkbox-group-2"
                                                                        v-model="filterTypeArr[index]"
                                                                        :aria-describedby="ariaDescribedby"
                                                                        name="specialityfilterItems">
                                                                        <b-form-checkbox checked="true" :value="filterData.filterID">{{filterData.filterName}}</b-form-checkbox>
                                                                    </b-form-checkbox-group>
                                                                </b-form-group>
                                                            </div>
                                                            <!-- / col -->
                                                            <!-- {{specialityfilter}} -->
                                                        </div>
                                                        <!-- / row -->
                                                        <hr>
                                                    <!-- </div> -->
                                                    <!-- / Speciality Div -->
                                                    
                                                    <div class="priceForTwo"  v-if="filter.displayType == 'range_silder'">
                                                        <VueSimpleRangeSlider
                                                                    activeBarColor="#f3766a"	
                                                                    class="align-center"
                                                                    style="width: 100%;margin: 55px 10px 20px 10px;"
                                                                    :min="0"
                                                                    :max="10000"
                                                                    :logarithmic="true"
                                                                    :value="filter.dataArr"
                                                                    v-model="rangeFilter"
                                                            />
                                                    </div>
                                                    <!-- / priceForTwo -->
                                                </div>
                                                <div class="filterBtns">
                                                    <button type="submit" @click="applyFilter" class="btn_1 mb_5" style="background:#ffc60c;color:#000!important;padding:10px 15px;font-size:16px;">Apply</button>
                                                    <button type="submit" @click="clearFilter" class="btn_1 mb_5" style="padding:10px 15px;font-size:16px;margin-left:5px;">Clear</button>
                                                </div>

                                             </div>
                                        </Drawer>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /row -->	
		    </div>
		    <span></span>
		</nav>

            <div class="container margin_30_20">			
                <div class="row">
                    <div class="col-lg-12" v-if="shimmer == 0">
                       <div class="row restaurantsListMargin" v-for="i in 2" v-bind:key="i">
                            <!-- <div class="col-12" style="margin-bottom:15px;"> -->
                                <h2 class="title_head"><VShimmerLine width="25" /></h2>
                                <!-- </div> -->
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" v-for="n in 12" v-bind:key="n">
                                <div class="strip">
                                    <figure>
                                        <VShimmerBlock width="290" heightPx="190" />
                                    </figure>
                                    
                                    <a href="#0"><h3 class="cateresTitle"><VShimmerLine width="60" /></h3></a>
                                            <p class="cateresMenuType one-line-text"><VShimmerLine width="75" /></p>
                                             <div class="rate-rating">
                                                    <span class="cateresPrice"><VShimmerLine width="20" /></span>
                                                    <div class="score CateresScore"><VShimmerLine width="5" /></div>
                                            </div>
                                </div>
                            </div>
                            <!-- /strip grid -->
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="shimmer == 1">
                        <div v-for="restaurantDetails in restaurantList" v-bind:key="restaurantDetails">
                        <div class="row" v-if="restaurantDetails && restaurantDetails.uiType == 'horizontal_list'">
                            <div class="col-12">
                                <!-- <div class="row" style="margin-bottom:15px;"> -->
                                    <!-- <div class="col-9"> -->
                                        <h2 class="title_head" v-if="restaurantDetails.name != ''">{{restaurantDetails.name}}</h2>
                                    <!-- </div> -->
                                     <!-- <div class="col-3 align-right">
                                        <a href="#" class="btn_1 mb_5" style="background:#f3766a!important;">Veiw All</a>
                                    </div> -->
                                <!-- </div> -->
                                <!-- <div class="owl-carousel owl-theme categories_carousel_in listing"> -->
                                 <carousel class="restaurantsListMargin" :navText="navIconsCaterer" :responsive="{0:{items:2,nav:true},600:{items:3,nav:true},1200:{items:4,nav:true},1800:{items:5,nav:true}}" :dots="false" :loop="true" :margin="23">
                                    <div v-for="restListCar in restaurantDetails.restaurantData" v-bind:key="restListCar">
                                        <router-link :to="{ name: 'restaurantDetailByShort', params: { id: restListCar.restaurantIdentifier} }">
                                        <div class="strip">
                                            <figure>
                                                <span class="ribbon1 off">15% off</span>
                                                <img :src="restListCar.bannerUrl == null || restListCar.bannerUrl == '' ? 'img' : restListCar.bannerUrl" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" height="190" width="319" class="img-fluid" alt="thumb">
                                                <span class="ribbonFoodType">
                                            <div class="foodType align-center" :style="{background : foodType.background}" v-for="foodType in restListCar.foodType" v-bind:key="foodType.name"  :id="foodType.name"  v-b-tooltip.hover="foodType.name" :title="foodType.name">
                                                        {{foodType.shortCode}}
                                            </div>
                                            <!-- <div class="foodType align-center" style="background: #ad4728">
                                                NV
                                            </div> -->
                                        </span>
                                            </figure>
                                            <a href="#0"><h3 class="cateresTitle">{{restListCar.restaurantName}}</h3></a>
                                                <!-- <p class="cateresMenuType one-line-text">Biryani, Lucknowi, Kebab, Muglai</p> -->
                                                <span class="cateresMenuType one-line-text" v-for="(specialityCuisines,index) in restListCar.cuisineList" v-bind:key="specialityCuisines.cuisineID" :id="'speciality'+restListCar.restaurantID"  v-b-tooltip.hover="specialityCuisines.cuisineName" :title="specialityCuisines.cuisineName">{{specialityCuisines.cuisineName}}<span v-if="index+1 < restListCar.cuisineList.length">, </span></span>
                                                <div class="rate-rating">
                                                        <span class="cateresPrice">₹{{restListCar.priceForTwo}} for two | {{restListCar.delivery_time}}</span>
                                                        <div class="score CateresScore" v-if="restListCar.rating > 0"><strong>{{restListCar.rating}}</strong></div>
                                                </div>
                                        </div>
                                        </router-link>
                                    </div>
                                <!-- </div> -->
                                </carousel>
                                <!-- /carousel -->
                            </div>
                        </div>
                        <!-- /row -->

                        <div class="row restaurantsListMargin" v-if="restaurantDetails && restaurantDetails.uiType == 'vertical_grid'">
                            <!-- <div class="col-12" style="margin-bottom:15px;"> -->
                                <h2 class="title_head" v-if="restaurantDetails.name != ''">Restaurants ({{restaurantDetails.restaurantData.length}})</h2>
                                <!-- </div> -->
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3" v-for="restList in restaurantDetails.restaurantData" v-bind:key="restList.restaurantID">
                              <router-link :to="bannerDetails.tabEnum && bannerDetails.tabEnum != '' && bannerDetails.tabEnum != null ? { name: 'restaurantDetails', params: { id: restList.restaurantIdentifier, mealType:bannerDetails.tabEnum} } : { name: 'restaurantDetailByShort', params: { id: restList.restaurantIdentifier} }">
                                <div class="strip">
                                    <figure>
                                        <!-- <span class="ribbon1 off">{{bannerDetails}}</span> -->
                                        <img :src="restList.bannerUrl == null || restList.bannerUrl == '' ? 'img' : restList.bannerUrl" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" height="319" width="322" class="img-fluid" alt="">
                                        <span class="ribbonFoodType">
                                            <div class="foodType align-center" :style="{background : foodType.background}" v-for="foodType in restList.foodType" v-bind:key="foodType.name">
                                                        {{foodType.shortCode}}
                                            </div>
                                        </span>
                                    </figure>
                                    
                                    <a href="#0"><h3 class="cateresTitle">{{restList.restaurantName}}</h3></a>
                                            <span class="cateresMenuType one-line-text" v-for="(specialityCuisines,index) in restList.cuisineList" v-bind:key="specialityCuisines.cuisineID" :id="'speciality'+restList.restaurantID"  v-b-tooltip.hover="specialityCuisines.cuisineName" :title="specialityCuisines.cuisineName">{{specialityCuisines.cuisineName}}<span v-if="index+1 < restList.cuisineList.length">, </span></span>
                                             <div class="rate-rating">
                                                    <span class="cateresPrice">₹200 for two | {{restList.delivery_time}}</span>
                                                    <div class="score CateresScore" v-if="restList.rating > 0">{{restList.rating}}</div>
                                            </div>
                                </div>
                            </router-link>
                            </div>
                            <!-- /strip grid -->
                        </div>
                        <!-- /row -->
                        <!-- <div class="pagination_fg">
                        <a href="#">&laquo;</a>
                        <a href="#" class="active">1</a>
                        <a href="#">2</a>
                        <a href="#">3</a>
                        <a href="#">4</a>
                        <a href="#">5</a>
                        <a href="#">&raquo;</a>
                        </div> -->
                        </div>
                        <!-- v-for -->
                    </div>
                    <!-- /col - lg - 12  -->
                </div>		
                <!-- row -->
            </div>
            <!-- /container -->
            
        </main>
        <!-- /main -->
        <footerLayout />
	<!-- footer layout -->
    </div>
</template>

    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/listing.css"></style>
    <style scoped src="../../templateAssests/css/detail-page.css"></style>
<style scoped>
main{
    background-color:#fff;
}
    .cards{
            max-width:350px;
            max-height:370px;
            border-radius:5px;
        }
        .cards span{
           border-radius: 0;
           right: 0;
           left: 15px;
           font-size: 12px;
           height: auto;
           width: 29%;
        }
        .searchCafes{
            /* padding-top: 12px; */
        }
        .cateresCards{
            max-width:350px;
            max-height:370px;
        }
        .cateresCards img{
            border-radius:5px;
        }
        .cateresTitle{
            font-size: 16px;
            color:#222;
            margin-bottom: 5px;
            margin-top:10px;
        }
        .cateresMenuType{
            margin-bottom: 5px;
            font-size: 14px;
            color:#777;
        }
        .cateresPrice
        {
            font-size: 14px;
            color:#777;
        }
        .CateresScore strong{
            padding: 0 0 0 0;
        }
        .score{
            float:right!important;
            color: #222;
        }
        .score strong:before{
            color:#FFC60C!important;
        }
        .restaurantsListMargin{
            margin-top:10px;
        }
        .ribbon1{
                color: #fff;
                display: inline-block;
                position: absolute;
                bottom: 10px;
                left: 16px;
                font-weight: 500;
                border-radius: 3px;
                padding: 6px 8px 4px 8px;
                line-height: 1;
                font-size: 12px;
                font-size: 0.75rem;
                z-index: 9;
        }
        .ribbon1.off {
            background-color: #f3723b;
        }
        .ribbonFoodType{
            color: #fff;
                display: inline-block;
                position: absolute;
                bottom: 10px;
                right: 16px;
                z-index: 9;
        }
        .secondary_nav {
            width: 100%;
            background-color: #fff;
            border-top: 1px solid #ededed;
            padding: 20px 0;
            position: relative;
        }
        .secondary_nav span {
            position: absolute;
            height: 7px;
            width: 100%;
            left: 0;
            bottom: 0;
            -webkit-box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 5%);
            -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 5%);
        }
        .bx{
            font-size: 24px;
        }
        .filter{
            cursor: pointer;
            display: block;
            width: 40px;
            height: 40px;
            line-height: 36px;
            border: 2px solid #f6f6f6;
            color: #74788d;
            border-radius: 4px;
            font-size: 24px;
            text-align:center;
            background:#f3766a;
        }
        .filter img{
            object-fit: contain;
        }
        .customAnch{
			color:#f3766a!important;
		}
        .customizeAddons{
			font-size:11.7px;
		}
        .dishType img{
            height:25px!important;
            width:25px!important;
        }
        .bx-filter-alt{    
            background: #f3766a;
            color: #fff;
    }
    .title_head{
        font-size: 26px;
        margin-bottom: 24px;
    }
    .page_header
    {
        background: #FFC60C;
        /* height: 240px!important; */
        padding: 40px 0;
    }
    ul#primary_nav {
		margin-bottom: 0px;
		padding-left: 0px;
	}
    ul#primary_nav li{
			display: inline-block;
    		margin-right: 10px;
			border-radius: 30px;
		}
		ul#primary_nav li a{
			background: #E6E6E6;
			padding: 0px 12px;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
		}
		ul#primary_nav li a.active{
			background: #F3766A;
			color: #fff!important;
			border:none!important;
		}
        .bannerTitle{
            color: #FFF;
            margin-bottom: 8px;
            font-family: 'GothamMedium'!important;
        }
        .bannerDesc{
            color: #FFF;
            font-size: 16px;
        }
        
        /* .vue-picker{
			display: block;
			width: 100%;
			font-size: 0.9rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #fff;
			border-radius: 5px;
        } */
</style>
<style>
/* .vue-picker__opener{ */
			/* border:transparent!important; */
			/* border:1px solid #777!important;

    } */
    .vue-picker{
			display: block;
			width: 100%;
			font-size: 0.9rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #fff;
			border-radius: 5px;
		}
		.vue-picker__dropdown{
			border:1px solid #fff!important;
		}
		.vue-picker__opener:focus{
			box-shadow: none!important;
			border:transparent!important;
		}
.swal2-confirm
{
    background:#FFC60C!important;

}
.bm-burger-button{
    top: 5px;
    right: 52px!important;
}
.bm-burger-button .bm-burger-bars
{
    display: none;
}
.bm-menu {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background: #fff;
}
.custom-control-label
{
    margin-left: 5px!important;
}
</style>


